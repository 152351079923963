import React, { useContext, useState, useMemo, useEffect } from 'react'
import {
  createStyles,
  Theme,
  Grid,
  TextField,
  SelectField,
  DateField,
  RadioButtonField,
  useFormikContext,
  FormikProps,
  Button,
  Modal,
} from '@rsmus/react-ui'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import { AppState } from '../../redux'
import {
  ENGAGEMENT_PHASE_INFO,
  FormModel,
  SelectFieldOption,
} from '../EngagementScene/models'
import { EngagementFormContext } from '../EngagementScene/EngagementScene'

const styles = ({ palette }: Theme) =>
  createStyles({
    container: {
      '& > :not(:last-child)': {
        paddingBottom: '.75rem',
      },
    },
    error: {
      border: `1px solid ${palette.informational.red}`,
    },
    fullSizeInput: {
      width: '100%',
      height: '2.5rem',
    },
    button: {
      display: 'inline-block !important',
      zIndex: 1,
      padding: '0.5rem',
      minWidth: '8rem',
      border: 0,
      borderRadius: '0.25rem',
      marginRight: '1rem',
    },
    engagementChangeButtonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
    },
    phaseChangeButton: {
      backgroundColor: palette.blue.main,
      color: palette.common.white,
      marginLeft: '20px !important',
      height: '34px !important',
    },
    clientAccessMessage: {
      color: palette.informational.red,
    },
   
  })

type StyleReturn = keyof ReturnType<typeof styles>
const useStyles = createUseStyles<Theme, StyleReturn>(styles)

interface EngagementFormProps {
  isRollForward: boolean
  onChangePhaseClicked: () => void
}

const EngagementForm = (props: EngagementFormProps) => {
  const { isRollForward } = props
  const classes = useStyles()
  const { errors, handleBlur, touched, values }: FormikProps<FormModel> =
    useFormikContext()
  const taxForms = useSelector((state: AppState) => state.taxForms)
  const [clientId, setClientId] = useState(values.clientId)
  const { handleClientIdChange } = useContext(EngagementFormContext)
  const [clientAccess, setClientAccess] = useState(true)
  const clientUsers = useSelector((state: AppState) => state.clientUsers)
  const userInfo: any | undefined = useSelector((state: AppState) => state.userInfo)

  const handleClientIdBlur = (e: any) => {
    const newVal = e.target.value
    if (clientId !== newVal) {
      handleClientIdChange(newVal)
      setClientId(newVal)
    }
    handleBlur(e)
  }

  useEffect(() => {
    if (values.engagementTaxForm === 'K-1Uploads') {
      values.pdfProcessingMethod = 'Trapeze'
      if (!values.ocrFolderName) {
        values.ocrFolderName = 'Exempt Org Vertical'
      }
    }
  }, [values.engagementTaxForm])

  useEffect(() => {
    if (clientId !== '' && !clientUsers.isLoading) {
        const user = clientUsers.data.find((x) => x.userId === userInfo.name)
        if(user !== undefined){
          setClientAccess(true)
        }else{
          setClientAccess(false)
        }
    }
  }, [clientUsers.data])

  const taxFormOptions = Array.from(
    new Set(taxForms.map((f: any) => f.type).sort())
  ).map((type) => ({
    label: `${type}`,
    value: `${type}`,
  }))

  const taxFormYears: SelectFieldOption[] = Array.from(
    new Set(taxForms.map((f: any) => f.year).sort())
  ).map((year) => ({
    label: `${year}`,
    value: `${year}`,
  }))

  let integratesWithCch = false
  if (values.engagementTaxForm && values.taxYear) {
    const taxForm = taxForms.find(
      (x) =>
        x.type === values.engagementTaxForm && `${x.year}` === values.taxYear
    )
    integratesWithCch = !!taxForm && taxForm.integrateWithCch
  }

  const fundTypes = [
    { label: 'Real Estate', value: 'RealEstate' },
    { label: 'Private Equity', value: 'PrivateEquity' },
    { label: 'Hedge Fund', value: 'HedgeFund' },
    { label: 'General', value: 'General' },
    { label: 'Hybrid', value: 'Hybrid' },
  ]

  const pdfProcessingMethods = [
    { label: 'MITS', value: 'MITS' },
    { label: 'Trapeze', value: 'Trapeze' },
    { label: 'Other', value: 'Other' },
  ]

  // Determine whether or not the user can change phases.
  const currentPhaseInfo = ENGAGEMENT_PHASE_INFO.find(
    (p) => p.value === values.phase
  )!

  const availableVersionNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9]

  const versionNumberOptions = availableVersionNumbers.map((i) => {
    return { label: `${i}`, value: i }
  })

  const isDisabled = !!values.id && !isRollForward

  return (
    <Grid
      container
      xs='12'
      md='12'
      lg='10'
      xl='8'
      className={classes.container}
    >
        <Grid container xs='12' spacing={2}>
          <Grid item xs='5'>
            <TextField
              name='clientId'
              labelText='Client ID'
              hintText='1234567'
              handleBlur={handleClientIdBlur}
              error={!!errors.clientId && touched.clientId}
              errorText={errors.clientId}
              disabled={isDisabled}
            />
          </Grid>

          <Grid item xs='12' md>
          {!clientAccess && (
            <div >
              <br></br>
              <div className={classes.clientAccessMessage}>You don't have access to the client.</div>
              <div className={classes.clientAccessMessage}>You will not be able to save the engagement.</div>
              <div className={classes.clientAccessMessage}>Request access on the dashboard.</div>
            </div>
          )}
        </Grid>
      </Grid>

      <Grid item xs='6'>
        <TextField
          name='name'
          labelText='Name of Engagement'
          hintText='If left blank, value will be set to TaxYear-FormType: Client Name.'
          error={!!errors.name && touched.name}
          handleBlur={handleBlur}
          errorText={errors.name}
        />
      </Grid>
      <Grid container xs='12' spacing={1}>
        <Grid item xs='12' md>
          {!!taxForms.length && (
            <SelectField
              labelText='Tax Form'
              name='engagementTaxForm'
              options={taxFormOptions}
              error={!!errors.engagementTaxForm && touched.engagementTaxForm}
              errorText={errors.engagementTaxForm}
              disabled={isDisabled}
              handleBlur={handleBlur}
              //handleChange = {handleTaxFormChange}
            />
          )}
        </Grid>
        <Grid item xs='12' md>
          {!!taxForms.length && (
            <SelectField
              labelText='Tax Year of Engagement'
              name='taxYear'
              options={taxFormYears}
              error={!!errors.taxYear && touched.taxYear}
              errorText={errors.taxYear}
              disabled={isDisabled}
              handleBlur={handleBlur}
            />
          )}
        </Grid>
      </Grid>

      {values.engagementTaxForm.indexOf('1065') > -1 ? (
        <Grid container xs='12'>
          <Grid item md>
            <RadioButtonField
              name='fundType'
              labelText='Fund Type'
              options={fundTypes}
              horizontal
              error={!!errors.fundType && touched.fundType}
              errorText={errors.fundType}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid container xs='12'>
        <Grid item xs='5'>
          <SelectField
            labelText='Pdf Processing Method'
            name='pdfProcessingMethod'
            options={pdfProcessingMethods}
            error={!!errors.pdfProcessingMethod && touched.pdfProcessingMethod}
            errorText={errors.pdfProcessingMethod}
            handleBlur={handleBlur}
          />
        </Grid>
      </Grid>

      {values.pdfProcessingMethod &&
      values.pdfProcessingMethod.indexOf('Trapeze') > -1 ? (
        <Grid container xs='12'>
          <Grid item xs='5'>
            <TextField
              name='ocrFolderName'
              labelText='OCR Folder Name'
              error={!!errors.ocrFolderName && touched.ocrFolderName}
              handleBlur={handleBlur}
              errorText={errors.ocrFolderName}
            />
          </Grid>
        </Grid>
      ) : null}
      {/* Hide field in K-1Uploads */}
      {values.engagementTaxForm.indexOf('K-1Uploads') === -1 ? (
        <Grid container xs='12'>
          <Grid item xs md='5'>
            <TextField
              name='priorYearReturnId'
              labelText='CCH Prior Year Return ID (Optional)'
              hintText='Example ID: 2016X:4568176-4568176:V1'
              handleBlur={handleBlur}
              error={!!errors.priorYearReturnId && touched.priorYearReturnId}
              errorText={errors.priorYearReturnId}
            />
          </Grid>
        </Grid>
      ) : null}

      {integratesWithCch ? (
        <Grid container xs='12'>
          <Grid item xs='5'>
            <SelectField
              options={versionNumberOptions}
              name='cchVersion'
              labelText='Version # of Current Year Return'
              error={!!errors.cchVersion && touched.cchVersion}
              errorText={errors.cchVersion}
            />
          </Grid>
        </Grid>
      ) : null}

      {currentPhaseInfo.showState !== false && (
        <Grid xs='12' container>
          <Grid item xs='5'>
            <TextField
              disabled={true}
              name='phaseName'
              labelText='Engagement Phase'
              error={!!errors.phase && touched.phase}
              errorText={errors.phase}
            />
          </Grid>
          <Grid
            item
            xs='5'
            direction='row'
            className={classes.engagementChangeButtonContainer}
          >
            <Button
              className={[classes.phaseChangeButton, classes.button].join(' ')}
              onClick={(e: any) => props.onChangePhaseClicked()}
              disabled={currentPhaseInfo.showState === 'disabled'}
              type='button'
            >
              UPDATE PHASE
            </Button>
          </Grid>
        </Grid>
      )}

      <Grid container xs='8'>
        <Grid item xs='3'>
          <DateField
            name='irsDateField'
            labelText='Due Dates'
            hintText='File Return'
            error={!!errors.irsDateField && touched.irsDateField}
            errorText={errors.irsDateField}
            closeOnSelect
            scrollIntoView
          />
        </Grid>
      </Grid>
      <Grid container xs='12'>
        <Grid item xs='12'>
          <i>Optional Dates:</i>
        </Grid>
        <Grid container xs='12'>
          <Grid item xs='12' sm='6' md>
            <DateField
              name='setupDateField'
              hintText='Setup'
              error={!!errors.setupDateField && touched.setupDateField}
              errorText={errors.setupDateField}
              closeOnSelect
              scrollIntoView
            />
          </Grid>
          <Grid item xs='12' sm='6' md>
            <DateField
              name='pbcDateField'
              hintText='PBC Tasks'
              error={!!errors.pbcDateField && touched.pbcDateField}
              errorText={errors.pbcDateField}
              closeOnSelect
              scrollIntoView
            />
          </Grid>
          <Grid item xs='12' sm='6' md>
            <DateField
              name='reviewDateField'
              hintText='Review'
              error={!!errors.reviewDateField && touched.reviewDateField}
              errorText={errors.reviewDateField}
              closeOnSelect
              scrollIntoView
            />
          </Grid>
          {values.engagementTaxForm.indexOf('990' || '990-EZ' || '990-PF') > -1 ? (
            <Grid item xs='12' sm='6' md>
              <DateField
                name='cchDateField'
                hintText='Submit to CCH'
                error={!!errors.cchDateField && touched.cchDateField}
                errorText={errors.cchDateField}
                closeOnSelect
                scrollIntoView
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EngagementForm
